<template>
  <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  Roles
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  label="Add"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="col-12">
      <div class="card" id="custom_card">
        <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
              <InputText    v-model="role_name"  > </InputText>
              <label>Role Name</label>
               
               </span>
              
            </div>
            <div class="my-2">
              &nbsp;<Button
                  icon="pi pi-search"
                  v-tooltip.bottom="'Search'"
                  class="btn_light_blue"
                  @click="search(1)"
                />
            </div>
            <div class="my-2">
              &nbsp;<Button class="btn_red" icon="pi pi-refresh" @click="search(2)" v-tooltip.bottom="'Refresh'"/>
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
                mode="basic"
                accept="image/*"
                :maxFileSize="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block"
              /> -->
            <!-- <Button  icon="pi pi-plus"  @click="openNew" v-tooltip.bottom="'Add'"/>&nbsp;
            <json-excel :data="product">
              <Button  icon="pi pi-upload"  @click="exportCSV" v-tooltip.bottom="'Export'"/>
            </json-excel> -->
          </template>
        </Toolbar>
        <!-- {{products}} -->
        <DataTable :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true"
          :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]" :rows="limit" :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll"
          style="font-size: 12px" :rowHover="true" showGridlines>
          <template #empty>
            <b style="text-align:center">No records found.</b>
          </template>
          <template #header>
            <div class="
                    flex flex-column
                    md:flex-row md:justify-content-between md:align-items-center
                  ">
              <h5 class="m-0"><b> Role Master</b></h5>
              <!-- Total Records:{{ totalRecords }} -->

              
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                  <i class="pi pi-search" />
                                  <InputText v-model="filters['global'].value" placeholder="Search..." />
                              </span> -->
            </div><div style="text-align:right;font-weight:bold"><span>Total Roles:</span>{{totalRecords}}</div>
          </template>
          <!-- <div class="card">
                  
                <InputText  format="number" placeholder="Name" v-model="name" > </InputText>&nbsp;
                <Dropdown style="width:150px" id="state" v-model="user_type_search" :options="user_type_dropdown" optionLabel="name" :optionValue="name" placeholder="Select Type"></Dropdown>
                &nbsp;<Button
                  icon="pi pi-search"
                  class="p-button-rounded p-button-success mr-2"
                  label="Search"
                  @click="search()"
                />
  
            </div> -->
          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <!-- <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[9]" >
              <template #body="{ data }"> 
                <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
              <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
              <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
              <span v-else>-</span>
              </template>
            </Column> -->

          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index }">
              <span v-if="page_no == 1">{{ +index + +1 }}</span>
              <span v-else>{{ (+index + 1) + limit * (page_no - 1) }}</span>
            </template>
          </Column>

          <!-- <Column header="Role ID" :field="columns[1]" style="text-align:center;">
              <template #body="{ data }">
                {{ data.user_role_id }}
              </template>
            </Column> -->
          <Column header="Role Name" :field="columns[2]" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.user_role_name }}
            </template>
          </Column>


          <Column header="Description" :field="columns[3]" style="min-width: 10rem">
            <template #body="{ data }">
              <span v-if="data.user_role_description">{{ data.user_role_description }}</span>
              <span v-else>-</span>

            </template>
          </Column>
          <!-- <Column header="City" :field="columns[3]" style="min-width: 5rem">
              <template #body="{ data }">
                <span v-if=" data.city">{{data.city}}</span>
                <span v-else>-</span>
              </template>
            </Column> -->
          <!-- <Column header="State" :field="columns[4]">
              <template #body="{ data }">
               <span v-if=" data.state">{{data.state}}</span>
                <span v-else>-</span>
              </template>
            </Column> -->
          <!-- <Column header="Pincode" :field="columns[5]">
              <template #body="{ data }">
                {{ data.pincode }}
              </template>
            </Column> -->
          <!-- <Column header="Pincode" :field="columns[5]" style="text-align:right;">
              <template #body="{ data }">
                <span v-if=" data.pincode">{{data.pincode}}</span>
                <span v-else>-</span>
              </template>
            </Column> -->
          <!-- <Column header="Mobile" :field="columns[9]" style="text-align:right;">
              <template #body="{ data }">
                {{ data.mobile_number }}
              </template>
            </Column>
            <Column header="Email" :field="columns[10]">
              <template #body="{ data }">
                {{ data.email_id }}
              </template>
            </Column> -->

          <Column header="Action" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" title="Edit Role" class="p-button-rounded p-button-help mr-2 btn_yellow" v-tooltip.bottom="'Edit'"
                @click="editProduct(slotProps.data)" />

              <Button  icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2 btn_red" v-tooltip.bottom="'View'"
                @click="confirmDeleteProduct(slotProps.data)" />

              <Button  icon="pi pi-arrow-right" class="p-button-rounded p-button-success mt-2 btn_green" v-tooltip.bottom="'Map Menu'"
                @click="mappProduct(slotProps.data)" />
            </template>
          </Column>
        </DataTable>





        <Dialog header="Add Role" v-model:visible="productDialog" :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '40vw' }" :modal="true">

          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                 
                  <InputText id="user_role_name" type="text" v-model.trim="product.user_role_name" required="true"
                    autofocus :class="{ 'p-invalid': submitted && !product.user_role_name }" />
                    <label for="user_role_name">Role Name</label>
                  </span>
                  </div>

                  <div class="field col-12 md:col-12"></div>
                <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                  
                  <InputText id="user_role_description" type="text" v-model.trim="product.user_role_description"
                    required="true" autofocus :class="{ 'p-invalid': submitted && !product.user_role_description }" />
                    <label for="startpoint">Role Description</label>
                  </span>
                </div>

                <div class="field col-12 md:col-12"></div>
                <div class="field col-12 md:col-12">
                 

                    <span class="p-float-label">
                  <Dropdown
                  id="state" filter 
                 
                  v-model="dashboard_link_search"  
                  :options="dashboard_link_dropdown" 
                  optionLabel="name" 
                  :optionValue="value"></Dropdown>
                  <label for="client">Dashboard Link</label>
                </span>


                </div>
                <div class="field col-12 md:col-12"></div>
                <div class="field col-12 md:col-12">
                 

                 <span class="p-float-label">
               <Dropdown
               id="state" filter 
              
               v-model="role_group_search"  
               :options="role_group_dropdown" 
               optionLabel="name" 
               :optionValue="value"></Dropdown>
               <label for="client">Role Group</label>
             </span>


             </div>
              </div>
            </div>
          </div>


          <template #footer>
            <Button v-if="product._id" label="Update" @click="addrole" icon="pi pi-check" class="p-button-warning btn_light_blue" />
            <Button v-else label="add" @click="addrole" icon="pi pi-check" class="p-button-warning btn_light_blue" />
          </template>
        </Dialog>

        <!-- ------------------------------------------------------------------------------------------------- -->

        <Dialog v-model:visible="mapp_productDialog" :style="{ width: '450px' }" header="Menu List" :modal="true">
          
          

          <div v-for="(menu_data, key) in menu_list" :key="key" class="form-group col-md-12 ">
         
            <div class="card">
              
            <div class="">

              <Checkbox v-model="selected_menu" inputId="ingredient1" :value="menu_data._id" /> &nbsp;
              
                                  <i :class="'text-2xl mb-2 pi pi-' + menu_data.icon"></i>&nbsp;<b>{{ menu_data.label }}</b> 
            </div>
            <div style="padding-left:50px" v-for="(menu_level1, key1) in menu_data.items" :key="key1">
              <hr><div class="">
                <Checkbox v-model="selected_menu" inputId="ingredient1" :value="menu_level1._id" />  &nbsp;
                
                                    <i :class="'text-2xl mb-2 pi pi-' + menu_level1.icon"></i>&nbsp;{{ menu_level1.label }}
              </div>
              <div style="padding-left:50px" v-for="(menu_level2, key2) in menu_level1.items" :key="key2">
                <hr><div class="">
                  <Checkbox v-model="selected_menu" inputId="ingredient1" :value="menu_level2._id" />  &nbsp;
                  
                                    <i :class="'text-2xl mb-2 pi pi-' + menu_level2.icon"></i>&nbsp;{{ menu_level2.label }}
                </div>
                <div style="padding-left:50px" v-for="(menu_level3, key3) in menu_level2.items" :key="key3">
                  <hr><div class="">
                    <Checkbox v-model="selected_menu" inputId="ingredient1" :value="menu_level3._id" />  &nbsp;
                    
                                    <i :class="'text-2xl mb-2 pi pi-' + menu_level3.icon"></i>&nbsp;{{ menu_level3.label }}
                  </div>
                </div>
              </div>
</div>
              </div>
            
          </div>

          <template #footer>

            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="maprole_menu" />
          </template>
        </Dialog>



        <!-- ------------------------------------------------------------------------------------------------- -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Are you sure you want to delete <b>{{ product.user_role_name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteuser" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Are you sure you want to delete the selected products?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import JsonExcel from "vue-json-excel";
import BreadcrumbService from "../service/BreadcrumbService";

//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {

      role_group_search:null,
      role_group_dropdown:[],

      dashboard_link_search:null,
      dashboard_link_dropdown:[ 
                                // {name: 'dashboard', value: 'dashboard'},
                                // {name: 'ClientDashboard', value: 'ClientDashboard'},
                                // {name: 'FleetManagerDashboard', value: 'FleetManagerDashboard'},
                                // {name: 'TransportDashboard', value: 'TransportDashboard'},
                                // {name: 'CorporateDashboard', value: 'CorporateDashboard'},
                                // {name: 'SbmDashboard', value: 'SbmDashboard'},
                                // {name: 'SbmCeoDashboard', value: 'SbmCeoDashboard'}
                                {name: 'Customer Dashboard', value: 'CustomerDashboard'},
                                {name: 'Admin Dashbboard', value: 'AdminDashboard'},
                                {name: 'Agent Dashboard', value: 'AgentDashboard'}
                              
                              ],
      role_name:'',
      place_details: '',
      end_place_details: '',
      searchme: '',
      name: '',
      page_no: 1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachment: "",

      pick_dropdown: [],
      end_pick_dropdown: [],
      pick_search: '',
      end_pick_search: '',

      user_type_dropdown: [{ name: 'School', code: 'School' }, { name: 'Industry', code: 'Industry' }, { name: 'Other', code: 'Other' }],
      user_type_search: '',


      groupdropdownItems: [{ name: "School", value: "School" },
      { name: "Industry", value: "Industry" },
      { name: "Other", value: "Other" }
      ],
      selectgroupstatus: { name: "", value: "" },

      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      selectstatus: { name: "", value: "" },

      menu_list: [],
      selected_menu: [],



      products: null,
      productDialog: false,
      mapp_productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,


      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],

      BreadcrumbService : null,
breadcrumbHome: {},
breadcrumbItems: [],
    };
  },
  productService: null,

  async created() {
    this.productService = new ProductService();

    this.BreadcrumbService = new BreadcrumbService();
	let Breadcrum_data = this.BreadcrumbService.rolemaster();
	this.breadcrumbHome=Breadcrum_data.home;
	this.breadcrumbItems=Breadcrum_data.items;

    this.initFilters();
    this.get_menu_list();
    this.get_role_group_list();

    

  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();

    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {

    maprole_menu() {
      if (!this.product._id) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Role Id Not found",
          life: 3000,
        });
        return false;
      }

      if (this.product._id) {
        var data = {
          "role_id": this.product._id,
          "menu_mapping": this.selected_menu
        };
        this.isLoadingModel = true;
        var promise = apis.saveRoleMenuMapping(data);
        promise
          .then((responseapi) => {

            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.mapp_productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }


    },

    mappProduct(product) {
      this.product = { ...product };

      this.get_menu_list(this.product.user_role_name)
      this.mapp_productDialog = true;
    },

    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      this.productDialog = true;
      this.dashboard_link_search=null;
      if (this.product.dashboard_link) {
        this.dashboard_link_search={name:this.product.dashboard_link,value:this.product.dashboard_link};
      } 
      if (this.product.role_group) {
        this.role_group_search={name:this.product.role_group,value:this.product.role_group};
      }   


    },



    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }
    },


    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;

      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    async search(count)
      { 
        if (count==2) 
        {
          this.role_group_search=null;
          this.dashboard_link_search=null;
        }
        count==2?this.role_name='':'';
        await this.get_list();
        await this.get_count();
      },
    get_count: function () {
      // var type='';
      // if(this.user_type_search!='')
      // {
      //   type=this.user_type_search.code;
      // }
      var data = {
        //   id: localStorage.getItem("id"),
             count: true,
        //   group_type:type,
        role_name:this.role_name
      };
      this.loading = true;
      var promise = apis.getRoleList(data);
      promise.then((response) => {
        this.loading = false;
        //   console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });

      // console.log(data);
    },
    get_list: function () {

      // var type='';
      // if(this.user_type_search!='')
      // {
      //   type=this.user_type_search.code;
      // }
      var data = {
           limit: this.limit,
           page_no: this.page_no,
           count: false,
          role_name:this.role_name

      };
      this.loading = true;
      var promise = apis.getRoleList(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {

            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    get_role_group_list()
    {  var data = {};
      var promise = apis.RoleGroupMasterList(data);
      promise.then((response) => {
        for (let a = 0; a < response.data.data.length; a++) 
        {
          
          this.role_group_dropdown.push({name:response.data.data[a].role_group_name,value:response.data.data[a].role_group_name})
        }
      });
    },
    get_menu_list: function (role_id) {
      var data={};
      if (role_id) 
      {
         data = { user_type: role_id };
      }
      else {
         data = { user_type: 'Show All' };
      }

      this.loading = true;
      var promise = apis.getMenuList(data);
      promise.then((response) => {
        this.loading = false;
        if (role_id) 
        {
          var role_menu=[];
          for (let x = 0; x < response.data.data.length; x++) 
          {
            role_menu.push(response.data.data[x]._id);
              var menu1=response.data.data[x].items;
              for (let y = 0; y < menu1.length; y++) 
              {
                role_menu.push(menu1[y]._id);
                   var menu2=menu1[y].items;
                  for (let z = 0; z < menu2.length; z++) 
                  {
                     role_menu.push(menu2[z]._id);
                     var menu3=menu2[z].items;
                      for (let m = 0; m < menu3.length; m++) 
                    {
                      role_menu.push(menu3[m]._id);
                    }
                   }
              }
              
          }
          
          this.selected_menu=role_menu;
        }
        else{
          this.menu_list = response.data.data;
        }
        



      });


    },

    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.role_group_search=null;
      this.dashboard_link_search=null;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async addrole() {
      this.submitted = true;

      if (!this.product.user_role_name) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Role Name",
          life: 3000,
        });
        return false;
      }



      if (!this.product.user_role_description) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter  Role Description",
          life: 3000,
        });
        return false;
      }






      //edit
      if (this.product._id) {


        var data = {
          "_id": this.product._id,
          "user_role_name": this.product.user_role_name,
          "user_role_description": this.product.user_role_description,
          "dashboard_link":this.dashboard_link_search?this.dashboard_link_search.value:'',
          "role_group":this.role_group_search?this.role_group_search.value:''
          //   "created_at": "",
          //   "updated_at": "",
          //   "created_by": localStorage.getItem("full_name"),
          //   "updated_by": ""
        };

        this.isLoadingModel = true;
        var promise = apis.editrole(data);
        promise
          .then((responseapi) => {

            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          "user_role_name": this.product.user_role_name,
          "user_role_description": this.product.user_role_description,
          "dashboard_link":this.dashboard_link_search?this.dashboard_link_search.value:'',
          "role_group":this.role_group_search?this.role_group_search.value:''
          //   "created_at": "",
          //   "updated_at": "",
          //   "created_by": localStorage.getItem("full_name"),
          //   "updated_by": ""
        };
        this.isLoadingModel = true;
        var promises = apis.addrole(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;

            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }


    },


    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product._id) {
        var data = {
          _id: this.product._id,
        };
        this.isLoadingModel = true;
        var promise = apis.deleterolemaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;

          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Role");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },

    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: { downloadExcel: JsonExcel },
};
</script>
  
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
  